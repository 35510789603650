<template>
  <div class="pl-3">
    <v-container class="container--fluid rounded-lg bg-white px-0 app-height-global mt-3">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="12"
        >
          <v-progress-linear
            color="#0C2FF3"
            background-color="#F2F4F7"
            value="70"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="flex justify-between px-8"
        >
          <div class="back-btn">
            <router-link :to="{ name: 'ImportCSV' }">
              <v-icon>mdi-chevron-left</v-icon> {{ $t('backToImportFromCSV') }}
            </router-link>
          </div>
          <router-link :to="{ name: 'ImportCSV' }">
            <v-icon size="20px">
              mdi-close
            </v-icon>
          </router-link>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="block main-area"
        >
          <h2 class="mb-8">
            {{ $t('columnMapping') }}
          </h2>
          <div
            v-for="(item, index) in importedHeaders"
            :key="index"
          >
            <template>
              <div class="d-flex align-center">
                <div class="text-left">
                  <v-label class="fs-14px text-theme-label font-weight-medium">
                    {{ $t('csvField') }}
                  </v-label>
                  <v-text-field
                    :value="item"
                    dense
                    height="38px"
                    background-color="#F9F9FB"
                    class="rounded-lg field-theme"
                    placeholder="Field"
                    disabled
                  />
                </div>
                <div class="mx-3">
                  <ArrorRightIcon />
                </div>
                <div class="text-left">
                  <v-label class="fs-14px text-theme-label font-weight-medium">
                    {{ $t('testFiestaField') }}
                  </v-label>
                  <v-select
                    v-model="selectedItems[index]"
                    dense
                    height="38px"
                    :items="importData"
                    class="rounded-lg field-theme custom-prepend"
                    item-value="value"
                    item-text="title"
                    placeholder="Test placeholder"
                    append-icon="mdi-chevron-down"
                    background-color="#F9F9FB"
                  />
                </div>
              </div>
            </template>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="d-flex justify-end pl-6 pr-10"
        >
          <v-btn
            class="text-capitalize btn-theme"
            depressed
            color="primary"
            height="38px"
            width="141px"
            @click="goToPreview"
          >
            {{ $t('goToPreview') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ArrorRightIcon from '@/assets/svg/arrow-right.svg';
export default {
  components: {
    ArrorRightIcon
  },
  props: ['data'],
  data(){
    return{
      importData: [
        { title: "Name", value: 'name' },
        { title: "ExternalId", value: 'externalId' },
        { title: "Source", value: 'source' },
        { title: "Priority", value: 'priority' },
        { title: "Tag", value: 'tag' },
      ],
      importedHeaders: [],
      importedData: [],
      selectedItems: [],
      dataArray: []
    }
  },
  created() {
    this.dataArray = this.$route.params.data;
    this.importedHeaders = this.dataArray.headers;
    if (this.dataArray?.data) {
      for (let i = 0; i < this.dataArray.data.length; i++) {
        if (this.dataArray?.data[i][0]) {
          let row = this.dataArray.data[i][0].split(';');
          this.importedData[i] = [];
          for (let j = 0; j < row.length; j++) {
            this.importedData[i].push(row[j])
          }
        }
      }
    }
    if (this.importedHeaders) {
      let headers = this.importedHeaders[0].split(';')
      for (let i = 0; i < headers.length; i++) {
        this.selectedItems.push(headers[i]);
      }
    }
  },
  methods: {
    goToPreview() {
      const mappingData = {
        title: this.selectedItems,
        data: this.importedData
      }
      this.$router.push({
        name: 'ImportPreview',
        params: { data: mappingData }
      })
    },
  },
}
</script>
<style scoped>
.v-align-start {
  align-items: flex-start !important;
}
.font-inter{
  font-family: Inter;
}
.card{
  border-radius: 8px;
}
.block{
  display: block;
}
.main-area{
  max-width: 524px;
  margin-left: auto;
  margin-right: auto;
}
.round-8{
  border-radius: 8px;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.bottom-0{
  bottom: 0;
}
.justify-end{
  justify-content: flex-end;
}
.justify-between{
  justify-content: space-between;
}
.text-white{
  color: white !important;
}
.round-6{
  border-radius: 6px;
}
.flex{
  display: flex;
}
.w-50{
  width: 50%;
}
.aling-left .v-list{
  text-align: left !important;
}
</style>
